
.funnel-date-range-filter {
  display:flex;
  align-items: center;
  justify-content: flex-end;
  //gap:10px;
  margin: 5px 0 20px 0;

  .date-range-label {
    font-weight: bold;
  }

  .date-range-control {
    position: relative;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 5px 0 0px;
    margin-right: 10px;
    border-left: 0;

    // select {
    //     margin-left: 10px;
    //     margin-right: 0;
    //     border-right: 0;
    //     border-left: 1px solid rgba(0,0,0,0.15);
    //     width: 75px;
    //     height: 35px;         
    // }

    &.from {
      margin-left: 10px;
      margin-right: 0;
      border-right: 0;
      border-left: 1px solid rgba(0,0,0,0.15);
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 50%;
        background: rgba(0,0,0,0.15);
        right: 0;
        top: 30%;
      }
    }

    label {
      display: block;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: rgba(0,0,0,.45);
      position: absolute;
      z-index: 1;
      top: -7px;
      background: #fff;
      padding: 0 6px;
    }
    .react-datepicker-wrapper {
      input {
        width: 100px;
        border:0;
        outline: 0;
        font-size: 14px;
        line-height: 1.5em;
        padding: 2px 12px;
      }
    }
  }
}