
.loginButton {   
    display: inline-block;
 }

 .modal-dialog {
    background-color: transparent;
}
.page {
    position: absolute;
    width: 100%;
}

.centralized {
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}




.slidecontainer {
    width: 100%; /* Width of the outside container */
}
  /* The slider itself */
.slider {
  width: 100%; /* Full-width */  
}
.slider::-webkit-slider-runnable-track {
    background: rgb(199, 233, 242);
}

.control-buttons{
    display: flex;
    align-items: center;
}

.control-button{
    cursor: pointer;
    margin-left: 20px;
}

.play {
    width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-left: 30px solid #555;
    border-bottom: 20px solid transparent;
    margin-left: 25px;
    cursor: pointer;
}

.stop{
    width: 40px;
    height: 40px;
    background-color:#555;
    margin-left: 30px;
    cursor: pointer;
}

.pause{
    width: 40px;
    height: 40px;
    /* border-style: solid; */
    border-width: 37px;
    border-color: #555;
    border-style: double;
    /* border-width: 0px 2px 0px 2px; */
    border-width: 0px 0px 0px 30px;
    /* border-color: #202020; */
    margin-left: 30px;
    cursor: pointer;
}


datalist {
    width: 121%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

datalist option {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
}

th, td {
    padding: 15px;
}

.main-menu {
    margin: 40px;


}

.main-menu .item  {
    margin: 40px;
    display: inline-block
}

.page-menu{
    margin: 20px;
}
.page-menu .item  {
    display: inline-block
}

.l1 {
    padding: 10px;
    
    color:rgb(99, 99, 219);
    vertical-align:middle;
}

.l1:hover {
    color:blue;    
}

.item:hover {
    transform: scale(1.2);
}

/* body {margin: 0; height: 100%; overflow: hidden} */
