
.partner-page {
  &.partner-page-users {

    main.MuiBox-root {
      /*margin-left: 0;
      padding: 24px;*/

      .MuiCard-root {
        margin: 0;
      }
    }

    .users-table-scroll-wrapper {
      height: calc(100vh - 185px);
      overflow: hidden;
    }

  }
}