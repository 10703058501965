
.error-alert-wrapper {
  display: flex;
  justify-content: center;

  .MuiAlert-root {
    padding: 8px 30px;

    .MuiAlert-message {
      .MuiAlertTitle-root {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}