
.page-sidebar {
  .MuiDrawer-paper {
    //position: relative;
    white-space: nowrap;
    width: 240px;
    padding-top: 60px;
    z-index: 1;

    .MuiToolbar-root {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 8px;
    }

    .MuiList-root {
      .MuiListItemButton-root {
        .MuiListItemIcon-root {
          min-width: 36px;
        }

        &.side-menu-submenu-button {
          white-space: normal;
          /*padding-top: 4px;
          padding-bottom: 4px;*/
          .MuiListItemText-root {
            margin:0;
          }
          .MuiListItemText-primary {
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  &.sidebar-collapsed {
    .MuiDrawer-paper {
      width: 70px;
      .MuiList-root {
        .MuiListItemButton-root {
          .MuiListItemIcon-root {
            justify-content: center;
          }
        }
      }
    }

    & + main.MuiBox-root {
      margin-left: 70px;
    }
  }

}