
.page-header {
  &.MuiAppBar-root {
    //position: static;
    flex-direction: row;
    //padding-left: 240px;
    .MuiToolbar-root {
      width: 100%;
      justify-content: space-between;
      padding-left: 10px;

      nav {
        .MuiButtonBase-root {
          margin: 0 8px;
        }
      }
    }

    .partner-page-logo {
      width: 44px;
      height: 44px;
    }

    #userAvatar {
      .MuiAvatar-root {
        width: 32px;
        height: 32px;
      }
    }

  }
}

#menu-appbar {
  margin-top: 40px;
}