


    .partner-card {
      &.MuiCard-root {
        height: 100%;
        margin-bottom: 0;

        .MuiCardActionArea-root {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: flex-start;
        }
        .MuiCardContent-root {
          padding-bottom: 15px;
          width: 100%;
          h4 {
            font-size: 1.15rem;
            margin: 15px 0;
            line-height: 1.2em;
            text-transform: capitalize;
          }
          table, td, tr, th {
            margin:0;
            padding: 0;
            height: 100%;
          }
          th {
                          font-size: 1.1rem;
                          color: rgba(0, 0, 0, 0.55);            
          }
          p {
            display: flex;
            gap: 6px;
            //justify-content: flex-end;
            align-items: baseline;
            margin-bottom: 0;

            b {
              //font-weight: normal;
              font-size: 1.1rem;
              //line-height: 1.2em;
              color: rgba(0, 0, 0, 0.55);
            }
          }
        }
      }
    }
