
.page-content-card {
  &.MuiCard-root {
    height: calc(100% - 24px);
    margin-bottom: 25px;

    &:last-child {
      height: 100%;
      margin-bottom: 0;
    }

    .MuiCardContent-root {
      position: relative;
      min-height: 150px;
      display: flex;
      flex-direction: column;

      /* Alex`s Loading Animation */
      h4.loading-animation {
        text-align: center;
      }

      /* React-Data-Table */
      .rdt_Table {
        .rdt_TableHead {
          .rdt_TableHeadRow {
            border-bottom-style: double;
            border-bottom-width: 3px;

            .rdt_TableCol {
              min-width: 50px;
              padding: 5px 10px;
              font-weight: bold;
              font-size: 13px;
              //background: #ededed;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            min-height: 40px;

            .rdt_TableCell {
              min-width: 50px;
            }
          }
        }
      }

      .page-content-card-title {
        margin: 0 -15px 15px -15px;
        border-bottom: 1px solid rgba(0,0,0,.15);
        padding: 5px 15px 18px;
        font-size: 0.93rem;
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-weight: normal;
          color: rgba(0,0,0,.65);
        }
      }
    }
  }
}