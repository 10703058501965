
main.main-container.MuiBox-root {
  margin-top: 65px;
  padding: 24px;
  margin-left: 240px;
  position: relative;
  min-height: calc(100vh - 65px);

  .MuiGrid-container,
  .MuiGrid-item {
    position: relative;
  }

  .page-title {
    margin-top: -10px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 8px;

    .MuiLink-root {
      display: flex;
      align-items: center;
      gap:6px;
      text-decoration: none;
    }

    span.crumb {
      font-size: 1rem;
      font-weight: normal;
      &:before {
        content: "//";
        display: inline-block;
        padding-right: 8px;
      }
    }
  }
}